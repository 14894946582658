<template lang="pug">
    .main-wrapper.grupos-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "grupos" }'> Grupos de Acesso</router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } grupo`")
                        .p-grid.p-fluid.p-align-start(style='margin: 0 auto;')

                            .p-col-12.p-md-9(:class="{ 'form-group--error': submitted && $v.model.nm_grupo.$error }")
                                label.form-label Descrição:
                                InputText(type='text' v-model='$v.model.nm_grupo.$model')
                                .feedback--errors(v-if='submitted && $v.model.nm_grupo.$error')
                                    .form-message--error(v-if="!$v.model.nm_grupo.required") <b>Descrição</b> é obrigatório.

                            .p-col-12.p-md-3
                                label.form-label Nível: {{ model.nr_nivel }}
                                Slider(v-model='model.nr_nivel' :step='1' :min='0' :max='9')

                            .p-col-9
                                label.form-label Endpoints: <span class='qtd-text'>URLs selecionadas: {{ model.cd_endpoint.length }}/{{ Object.keys(options.endpoints).length }}</span>
                                .p-inputgroup.my-2
                                    InputText(v-model='endpointsFilter')
                                    Button(v-if='endpointsFilter.length' icon='jam jam-rubber' type='button' @click="endpointsFilter = ''")
                                    Button(v-else icon='jam jam-search' type='button')
                                .endpoints-container
                                    .p-grid.p-fluid.p-align-center.endpoint-row(v-for='val, key, idx in filteredEndpoints()' :key='key' :class="{ odd: idx % 2 != 0 }")
                                        .p-col-12.p-md-7
                                            p.text-url {{ val.ds_url }}
                                        .p-col-12.p-md-5
                                            .p-grid.p-fluid.p-align-center
                                                .p-col-3
                                                    InputSwitch(v-model='options.endpoints[key].GET' @input="onInput(key, 'GET', $event)")
                                                    span.text-method GET
                                                .p-col-3
                                                    InputSwitch(v-model='options.endpoints[key].POST' @input="onInput(key, 'POST', $event)")
                                                    span.text-method POST
                                                .p-col-3
                                                    InputSwitch(v-model='options.endpoints[key].PATCH' @input="onInput(key, 'PATCH', $event)")
                                                    span.text-method PATCH
                                                .p-col-3
                                                    InputSwitch(v-model='options.endpoints[key].DELETE' @input="onInput(key, 'DELETE', $event)")
                                                    span.text-method DELETE

                            .p-col-3
                                label.form-label.ta-center Menus:
                                .menus-container
                                    .p-field-checkbox(v-for='menu in options.menus' :key='menu.id' :class="{ child: ! menu.children && ! menu.noChild }")
                                        Checkbox(name='menus' :value='menu.id' :disabled='menu.children' v-model='model.cd_menu' @input='onMenuSelect(menu)')
                                        label {{ menu.nm_menu }}

                            .p-col-12.mt-2.ta-center
                                Button(label='Salvar' type="submit" style='max-width:200px;')     
</template>

<style lang="scss">
    .grupos-salvar {
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .qtd-text {
            color: #657786;
            font-size: 12px;
            float: right;
        }
        .endpoints-container, .menus-container {
            max-height: 400px;
            overflow-y: scroll;
            border: 1px solid #a6a6a6;
            border-radius: 3px;
        }
        .menus-container {
            max-height: 452px;
            .p-field-checkbox {
                padding: 10px;
                margin: 0;
                &.child { padding-left: 40px; }
            }
        }
        .endpoint-row {
            margin: 0;
            padding: 0 10px;
            &.odd {
                background-color: #eee;
            }
            .text-url {
                margin: 0;
                font-size: 13px;
                font-weight: 700;
            }
            .text-method {
                display: inline-block;
                vertical-align: middle;
                margin-left: 6px;
                font-size: 13px;
                font-weight: 700;
            }
            .p-inputswitch {
                vertical-align: middle;
            }
        }
        .p-slider {
            margin: 15px 0;
        }
        .p-slider-handle {
            cursor: pointer !important;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import InputSwitch from 'primevue/inputswitch'
    import Slider from 'primevue/slider'
    import Checkbox from 'primevue/checkbox'

    import { Grupos, Endpoints, Menus } from './../../middleware'
    import { required, minLength } from 'vuelidate/lib/validators'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Slider, Checkbox,
                InputMask, SelectButton, Dropdown, Tooltip, InputSwitch
        },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true
            Menus.findAll().then(response => {
                if (response.status == 200) {
                    let parents = {}
                    // response.data.sort((a, b) => a.nm_menu > b.nm_menu ? -1 : 1)
                    response.data.forEach(menu => { if (! menu.cd_sub_menu) parents[menu.id] = [] })
                    response.data.forEach(menu => { if (menu.cd_sub_menu) parents[menu.cd_sub_menu].push(menu.id) })

                    Object.keys(parents).forEach(key => {
                        let find = response.data.find(menu => menu.id == key)
                        if (parents[key].length) find.children = []
                        else find.noChild = true
                        this.options.menus.push(find)
                        parents[key].forEach(id => {
                            find = response.data.find(menu => menu.id == id)
                            this.options.menus.push(find)
                        })
                    })
                }
                Endpoints.findAll().then(response => {
                    if (response.status == 200) {
                        response.data.forEach(endpoint => {
                            this.options.endpoints[endpoint.id] =
                                { 'id': endpoint.id, 'ds_url': endpoint.ds_url, 'GET': false, 'POST': false, 'PATCH': false, 'DELETE': false }
                        })
                        let id = 0
                        if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
                        if (id != 0) {
                            Grupos.find(id).then(response => {
                                if (response.status == 200) {
                                    Object.keys(this.model).forEach(key => { if (response.data[key]) this.model[key] = response.data[key] })
                                    this.model.id = id
                                    this.model.cd_endpoint.forEach(endpoint => {
                                        endpoint.ds_metodo_http.forEach(metodo => {
                                            this.options.endpoints[endpoint.cd_endpoint][metodo] = true
                                        })
                                    })
                                    
                                    this.model.cd_menu.forEach(menu => {
                                        let find = this.options.menus.find(m => m.id == menu)
                                        if (find.cd_sub_menu) {
                                            let parent = this.options.menus.find(m => m.id == find.cd_sub_menu)
                                            parent.children.push(menu)
                                        }
                                    })
                                    
                                }
                                this.waiting = false
                            })
                        } else this.waiting = false  
                    }
                })
            })
        },
        data () {
            return {
                model: {
                    nr_nivel: 0,
                    nm_grupo: '',
                    cd_endpoint: [],
                    cd_menu: []
                },
                options: {
                    endpoints: {},
                    menus: []
                },
                waiting: true,
                waitingForm: false,
                submitted: false,
                endpointsFilter: '',
                filteredEndpoints () {
                    if (! this.endpointsFilter.length) return this.options.endpoints
                    let query = Object.values(this.options.endpoints).filter(endpoint => endpoint.ds_url.includes(this.endpointsFilter))
                    let result = {}
                    query.forEach(endpoint => result[endpoint.id] = endpoint)
                    return result
                }
            }
        },
        validations () {
            let v = {
                model: {
                    nm_grupo: { required, minLength: minLength(2) },
                }
            }
            
            return v
        },
        methods: {
            handleSubmit () {
                this.submitted = true
                
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)
                if (! dataSend.cd_menu) dataSend.cd_menu = []
                
                this.waitingForm = true
                Grupos.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'grupos' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.non_field_errors)
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                    }
                    this.waitingForm = false
                })
            },
            onInput (cd_endpoint, method, checked) {
                this.options.endpoints[cd_endpoint][method] = checked

                let endpoint = this.model.cd_endpoint.find(endpoint => endpoint.cd_endpoint == cd_endpoint)

                if (endpoint) {
                    this.model.cd_endpoint = this.model.cd_endpoint.filter(endpoint => endpoint.cd_endpoint != cd_endpoint)
                } else {
                    endpoint = { cd_endpoint, ds_metodo_http: [] }
                }

                if (checked) {
                    endpoint.ds_metodo_http.push(method)
                } else {
                    endpoint.ds_metodo_http = endpoint.ds_metodo_http.filter(ds_metodo_http => ds_metodo_http != method)
                }

                if (endpoint.ds_metodo_http.length) this.model.cd_endpoint.push(endpoint)            

                this.$forceUpdate()
            },
            onMenuSelect (menu) { // eslint-disable-line
                let findParent = this.options.menus.find(m => m.id == menu.cd_sub_menu)
                if (! findParent) return 0
                let checked = this.model.cd_menu.includes(menu.id)
                if (checked) {
                    if (! this.model.cd_menu.includes(findParent.id)) this.model.cd_menu.push(findParent.id)
                    findParent.children.push(menu.id)
                } else {
                    findParent.children = findParent.children.filter(id => id != menu.id)
                    if (! findParent.children.length) this.model.cd_menu = this.model.cd_menu.filter(id => id != findParent.id)
                }
            }
         }
      }
</script>